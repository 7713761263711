import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import Home from "./Home";
import Franchise from "./Franchise";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import "./styles/style.css";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_1_back-to-top.js"; // 👈 make sure to use the correct path
    frontEnd.id = "_1_back-to-top-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_1_back-to-top-js").remove();
    };
  }, []);

  useEffect(() => {
    const hash = location.hash;
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      const scroll = new SmoothScroll(".js-scroll", {
        speed: 300,
      });
      scroll.animateScroll(el);
    }
  }, [location.hash]);

  return (
    <div className="App">
      {/* <div>{i18n.language}</div> */}
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/franchise" element={<Franchise />} />
      </Routes>

      <Footer />

      <a className="back-to-top js-back-to-top" href="#0" data-offset={100} data-duration={300}>
        <svg className="icon" viewBox="0 0 20 20">
          <polyline
            points="2 13 10 5 18 13"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </svg>
      </a>

      <ScrollToTop />
    </div>
  );
}
